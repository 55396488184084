// Tables
export const FIRESTORE_ORDERS_TABLE = "orders";
export const FIRESTORE_PRODUCTS_TABLE = "products";
export const FIRESTORE_PROMOTIONS_TABLE = "promotions";
export const FIRESTORE_USERS_TABLE = "users";
export const FIRESTORE_INVITED_USERS_TABLE = "invitedUsers";
export const FIRESTORE_DISCOUNT_CODES_TABLE = "discountCodes";
export const FIRESTORE_GROUPS_TABLE = "groups";
export const FIRESTORE_COLLECTIONS_TABLE = "collections";
export const FIRESTORE_FEATURED_PRODUCTS_TABLE = "featuredProducts";
export const FIRESTORE_SESSIONS_TABLE = "sessions";
export const FIRESTORE_CATEGORIES_TABLE = "categories";
export const FIRESTORE_CARTS_TABLE = "carts";
export const FIRESTORE_PURCHASE_ORDERS_TABLE = "purchaseOrders";
export const FIRESTORE_CONFIG = "config";

// Variables
export const FIRESTORE_PRODUCT_VARIATIONS_PRICE = "price";
export const FIRESTORE_DEFAULT_ORDERS_MAX_DAYS = 30;
