import {
  ApartmentOutlined,
  FileTextOutlined,
  GiftOutlined,
  GroupOutlined,
  LineChartOutlined,
  RadarChartOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import i18n from "translations/i18n";
import urls from "urls";

const shopNavTree = [
  {
    key: "shop",
    title: i18n.t("shop"),
    breadcrumb: false,
    submenu: [
      {
        key: "shop-reports",
        path: urls.reports,
        title: i18n.t("reports"),
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-products",
        title: i18n.t("products"),
        icon: ShoppingCartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "shop-products-productList",
            path: urls.productList,
            title: i18n.t("products"),
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "shop-products-addProduct",
            path: urls.addProduct,
            title: i18n.t("add_product"),
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "shop-products-featuredProducts",
            path: urls.featuredProducts,
            title: i18n.t("featured"),
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "shop-categories",
        path: urls.categories,
        title: i18n.t("categories"),
        icon: GroupOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-discounts",
        title: i18n.t("discounts"),
        icon: GiftOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "shop-discounts-promotions",
            path: urls.promotions,
            title: i18n.t("promotions"),
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "shop-discounts-discountCode",
            path: urls.discountCodes,
            title: i18n.t("discount_codes"),
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "shop-orders",
        title: i18n.t("order.orders"),
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "shop-orders-all",
            path: urls.ordersList,
            title: i18n.t("order.orders"),
            icon: "",
            submenu: [],
          },
          {
            key: "shop-orders-exact",
            path: urls.exactOrdersList,
            title: i18n.t("order.exact"),
            icon: "",
            submenu: [],
          },
          {
            key: "shop-orders-purchase-orders",
            path: urls.purchaseOrdersList,
            title: i18n.t("order.purchase_orders"),
            icon: "",
            submenu: [],
          },
        ],
      },
      {
        key: "shop-sessions",
        path: urls.sessions,
        title: i18n.t("sessions"),
        icon: RadarChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-customers",
        title: i18n.t("customers"),
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "shop-customers-existing",
            path: urls.customerList,
            title: i18n.t("customers_all"),
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "shop-customers-pending",
            path: urls.customerListPending,
            title: i18n.t("customers_pending"),
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const adminNavTree = [
  {
    isAdmin: true,
    key: "admin",
    title: i18n.t("admin"),
    breadcrumb: false,
    submenu: [
      {
        key: "admin-collections",
        path: urls.collections,
        title: i18n.t("collections"),
        icon: ApartmentOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "admin-groups",
        path: urls.groups,
        title: i18n.t("groups"),
        icon: GroupOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "admin-users",
        path: urls.users,
        title: i18n.t("users"),
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...shopNavTree, ...adminNavTree];

export default navigationConfig;
